import { graphql } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import Layout from "../components/Layouts/layout"
import Architecture from "../components/Projects/architecture"
import SEO from "../components/seo"

const PageArchitecture = props => {
  // console.log(props.data.wpPage.seo)

  let {
    location,
    data: {
      wpPage: { title, seo },
    },
  } = props

  return (
    <Layout location={location} title={title}>
      <SEO bodyClasses="casestudies architecture" seo={seo} />
      <div className="container-fluid content d-none" id="content">
        <div className="row">
          <div className="col-12">
            {!!title && <h1 className="heading">{parse(title)}</h1>}
          </div>
        </div>
      </div>
      <Architecture title={title} />
    </Layout>
  )
}

export default PageArchitecture

export const pageArchitecture = graphql`
  {
    wpPage(databaseId: { eq: 10 }) {
      id
      title
      seo {
        ...WpYoastSEO
      }
    }
  }
`
